import React from "react";
import "./App.css"; // Make sure this file imports Tailwind CSS

import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import Dexscreener from "./Assets/dex-screener.png";
import Dextools from "./Assets/dt-logo-black.png";
import SolScan from "./Assets/solscan.png";
import Jupiter from "./Assets/jupiter-logo.png";
import Raydium from "./Assets/Raydium-RAY-coin.webp";
import Xicon from "./Assets/x-logo.png";
import Telegram from "./Assets/tg-plane.png";
import purplepinkhead from "./Assets/Scrappy Racoon Images/purple-pink-head.png";
import purplepinkbody from "./Assets/Scrappy Racoon Images/purple-pink-body.png";
import purpleyellowbody from "./Assets/Scrappy Racoon Images/purple-yellow-body.png";
import { motion } from "framer-motion";

const contractAddress = "0x1234567890abcdef";
const jupiterLink = "https://jupiter.io"; 
const raydiumLink = "https://raydium.io"; 
const dexscreenerLink = "https://dexscreener.com"; 
const dextoolsLink = "https://dextools.io"; 
const solscanLink = "https://solscan.io"; 
const telegramLink = "https://t.me/+zC63dTgscCkzMWY0"; 
const twitterLink = "https://x.com/ScrappyRacoon"; 

function copyToClipboard() {
  navigator.clipboard.writeText(contractAddress).then(
    () => {
      alert("Contract address copied to clipboard!");
    },
    (err) => {
      console.error("Could not copy text: ", err);
    }
  );
}

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } },
};

const slideUp = {
  hidden: { y: 50, opacity: 0 },
  visible: { y: 0, opacity: 1, transition: { duration: 1 } },
};

const scaleUp = {
  hidden: { scale: 0.95, opacity: 0 },
  visible: { scale: 1, opacity: 1, transition: { duration: 1 } },
};

function App() {
  return (
    <div className="text-darkGray body-font">
      <header className="bg-primary-purple body-font">
        <div className="container mx-auto flex flex-wrap p-4 flex-col md:flex-row items-center">
          <a
            href="/"
            className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
          >
            <img
              src={purplepinkhead}
              className="h-12 w-38 bg-white px-2 rounded-lg"
              alt="Scraps racoon"
            />
          </a>
          {/* Navigation hidden on small screens and visible on medium and up */}
          <nav className="hidden md:flex md:ml-auto md:mr-auto flex-wrap items-center text-base justify-center font-bangers tracking-widest">
            <a
              href="#about"
              className="mr-5 text-secondary-purple hover:text-white hover:bg-secondary-purple border border-white bg-white px-3 py-2 rounded transition duration-300 ease-in-out transform hover:-translate-y-1"
            >
              About
            </a>
            <a
              href="#how-to-buy"
              className="mr-5 text-secondary-purple hover:text-white hover:bg-secondary-purple border border-white bg-white px-3 py-2 rounded transition duration-300 ease-in-out transform hover:-translate-y-1"
            >
              How to buy
            </a>

            <a
              href="#roadmap"
              className="mr-5 text-secondary-purple hover:text-white hover:bg-secondary-purple border border-white bg-white px-3 py-2 rounded transition duration-300 ease-in-out transform hover:-translate-y-1"
            >
              Roadmap
            </a>

            <a
              href="#tokenomics"
              className="mr-5 text-secondary-purple hover:text-white hover:bg-secondary-purple border border-white bg-white px-3 py-2 rounded transition duration-300 ease-in-out transform hover:-translate-y-1"
            >
              Tokenomics
            </a>
            <a
              href="#buy-now"
              className="text-secondary-purple hover:text-white hover:bg-secondary-purple border border-white bg-white px-3 py-2 rounded transition duration-300 ease-in-out transform hover:-translate-y-1"
            >
              Buy
            </a>
          </nav>
          {/* Social media icons always visible, but layout adjusts on mobile */}
          <div className="flex justify-center md:justify-start w-full md:w-auto">
            <a
              href={twitterLink}
              target="_blank"
              className="mx-2 hover:text-white transition-transform duration-300 transform hover:scale-110"
            >
              <FaTwitter className="text-white w-10 h-10" />
            </a>
            <a
              href={telegramLink}
              target="_blank"
              className="mx-2 hover:text-white transition-transform duration-300 transform hover:scale-110"
            >
              <FaTelegramPlane className="text-white w-10 h-10" />
            </a>
          </div>
        </div>
      </header>

      <motion.section
        id="about"
        className="text-white min-h-screen flex flex-col justify-center body-font"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={fadeIn}
      >
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-center flex-grow py-24 px-5">
          <div className="flex flex-col md:flex-row w-full">
            <motion.div
              className="lg:flex-grow md:w-1/2 flex flex-col justify-between md:items-start md:text-left items-center text-center bg-primary-purple md:shadow-2xl px-6 py-8 md:p-12 rounded-lg font-bangers mx-4"
              variants={slideUp}
            >
              <div>
                <motion.h1
                  className="title-font mb-6 text-4xl sm:text-5xl md:text-6xl lg:text-6xl font-medium text-vivid-pink tracking-wider"
                  style={{
                    textShadow:
                      "2px 2px 0 #fff, -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff",
                  }}
                  variants={scaleUp}
                >
                  Welcome to $SCRAPS
                </motion.h1>
                <p className="mb-8 text-sm sm:text-base md:text-lg lg:text-xl leading-relaxed tracking-wider text-white">
                  SCRAPS THE RACOON IS THE FIESTY MASCOT OF THE $SCRAPS TOKEN.
                  HE IS A LOYAL FRIEND AND A FIERCE COMPANION. HE IS THE GUARD
                  OF ALL ANIMAL MEME COINS AND WILL PROTECT THEM WITH HIS LIFE.
                </p>
                <p className="mb-8 text-sm sm:text-base md:text-lg lg:text-xl leading-relaxed tracking-wider text-white flex items-center space-x-2">
                  <strong>Contract Address:</strong>
                  <span className="bg-white text-secondary-purple px-2 py-1 rounded">
                    {contractAddress}
                  </span>
                  <motion.button
                    onClick={copyToClipboard}
                    className="bg-secondary-purple text-white px-2 py-1 rounded ml-2"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    Copy
                  </motion.button>
                </p>
              </div>
              <div className="mt-auto w-full">
                <div className="flex justify-center md:justify-center items-center w-full">
                  {/* Repeat similar for other icons */}
                  <a
                    href={twitterLink}
                    target="_blank"
                    className="hover:text-white transition-transform duration-300 transform hover:scale-110 mx-2"
                  >
                    <motion.img
                      src={Xicon}
                      className="w-8 h-8 md:w-12 md:h-12 lg:w-14 lg:h-14"
                      alt="X icon"
                      variants={scaleUp}
                    />
                  </a>
                  {/* Continue with other icons */}
                  <a
                    href={telegramLink}
                    target="_blank"
                    className="hover:text-white transition-transform duration-300 transform hover:scale-110 mx-2"
                  >
                    <motion.img
                      src={Telegram}
                      className="w-8 h-8 md:w-12 md:h-12 lg:w-14 lg:h-14"
                      alt="X icon"
                      variants={scaleUp}
                    />
                  </a>
                  <a
                    href={dexscreenerLink}
                    target="_blank"
                    className="hover:text-white transition-transform duration-300 transform hover:scale-110 mx-2"
                  >
                    <motion.img
                      src={Dexscreener}
                      className="w-8 h-8 md:w-12 md:h-12 lg:w-14 lg:h-14 rounded-full"
                      alt="X icon"
                      variants={scaleUp}
                    />
                  </a>
                  <a
                    href={dextoolsLink}
                    target="_blank"
                    className="hover:text-white transition-transform duration-300 transform hover:scale-110 mx-2"
                  >
                    <motion.img
                      src={Dextools}
                      className="w-8 h-8 md:w-12 md:h-12 lg:w-14 lg:h-14"
                      alt="X icon"
                      variants={scaleUp}
                    />
                  </a>
                  <a
                    href={solscanLink}
                    target="_blank"
                    className="hover:text-white transition-transform duration-300 transform hover:scale-110 mx-2"
                  >
                    <motion.img
                      src={SolScan}
                      className="w-8 h-8 md:w-12 md:h-12 lg:w-14 lg:h-14"
                      alt="X icon"
                      variants={scaleUp}
                    />
                  </a>
                  <a
                    href={jupiterLink}
                    target="_blank"
                    className="hover:text-white transition-transform duration-300 transform hover:scale-110 mx-2"
                  >
                    <motion.img
                      src={Jupiter}
                      className="w-8 h-8 md:w-12 md:h-12 lg:w-14 lg:h-14"
                      alt="X icon"
                      variants={scaleUp}
                    />
                  </a>
                  <a
                    href={raydiumLink}
                    target="_blank"
                    className="hover:text-white transition-transform duration-300 transform hover:scale-110 mx-2"
                  >
                    <motion.img
                      src={Raydium}
                      className="w-8 h-8 md:w-12 md:h-12 lg:w-14 lg:h-14"
                      alt="X icon"
                      variants={scaleUp}
                    />
                  </a>
                </div>
              </div>
            </motion.div>

            <motion.div
              className="lg:flex-grow md:w-1/2 flex items-center justify-center mx-4 mt-8 md:mt-0"
              variants={scaleUp}
            >
              <img
                className="w-full h-auto max-w-full object-cover object-center rounded-lg"
                src={purplepinkbody}
                alt="Scraps"
              />
            </motion.div>
          </div>
        </div>
      </motion.section>

      <motion.section
        id="how-to-buy"
        className="bg-primary-purple min-h-screen flex items-center justify-center body-font"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={fadeIn}
      >
        <div className="container w-full px-5 py-24 flex flex-col md:flex-row items-center justify-center flex-grow">
          <motion.div
            className="bg-pale-yellow shadow-2xl m-12 p-12 rounded-lg flex-grow flex flex-col md:items-start md:text-left items-center text-center w-full font-bangers"
            variants={slideUp}
          >
            <motion.h2
              className="title-font sm:text-6xl text-5xl mb-6 font-medium text-vivid-pink tracking-wider"
              style={{
                textShadow:
                  "2px 2px 0 rgb(153 70 255), -2px -2px 0 rgb(153 70 255), 2px -2px 0 rgb(153 70 255), -2px 2px 0 rgb(153 70 255)",
              }}
              variants={scaleUp}
            >
              How to Buy
            </motion.h2>
            <div className="leading-relaxed lg:text-2xl sm:text-xl tracking-wider text-primary-purple space-y-6">
              <p>
                1. <strong>Create a Crypto Wallet:</strong> Download a wallet
                app like Phantom or Solflare on your mobile device or add the
                browser extension on your PC.
              </p>
              <p>
                2. <strong>Get some Solana (SOL):</strong> You can buy SOL
                through Phantom using Moonpay, transfer from another wallet,
                bridge from any blockchain, or purchase on Swissborg or KuCoin
                and transfer to your wallet.
              </p>
              <p>
                3. <strong>Swap SOL for $SCRAPS:</strong> Go to Jupiter or
                Raydium, connect your wallet, and swap your SOL for $SCRAPS.
              </p>
              <p>
                4. <strong>Contract Address:</strong> Make sure to paste the
                $SCRAPS contract address in the receive box to ensure you are
                buying the correct coin.
                <span className="bg-primary-purple text-white ml-1 p-1 rounded">
                  {contractAddress}
                </span>
                <motion.button
                  onClick={copyToClipboard}
                  className="bg-secondary-purple text-white p-1 rounded ml-2"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Copy
                </motion.button>
              </p>
            </div>
          </motion.div>
        </div>
      </motion.section>

      <motion.section
        id="roadmap"
        className="text-primary-purple bg-white min-h-screen flex items-center justify-center body-font"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={fadeIn}
      >
        <div className="container w-full px-5 py-24 flex flex-col md:flex-row text-center items-center justify-center flex-grow">
          <motion.div
            className="bg-primary-purple shadow-2xl m-12 p-12 rounded-lg flex-grow flex flex-col md:items-start md:text-left items-center text-center w-full font-bangers"
            variants={slideUp}
          >
            <motion.h2
              className="title-font sm:text-6xl text-5xl mb-6 font-medium text-white tracking-wider"
              style={{
                textShadow:
                  "2px 2px 0 rgb(252 74 157), -2px -2px 0 rgb(252 74 157), 2px -2px 0 rgb(252 74 157), -2px 2px 0 rgb(252 74 157)",
              }}
              variants={slideUp}
            >
              Roadmap
            </motion.h2>

            <div className="flex flex-wrap md:flex-row w-full justify-center items-stretch gap-4">
              {/* Phase 1 */}
              <motion.div
                className="flex-grow lg:w-1/3 flex flex-col items-center text-center bg-pale-yellow shadow-2xl p-12 rounded-lg font-bangers"
                variants={slideUp}
              >
                <h3
                  className="title-font sm:text-4xl text-3xl mb-6 font-medium text-vivid-pink tracking-widest"
                  style={{
                    textShadow:
                      "2px 2px 0 rgb(153 70 255), -2px -2px 0 rgb(153 70 255), 2px -2px 0 rgb(153 70 255), -2px 2px 0 rgb(153 70 255)",
                  }}
                >
                  Phase 1: Foundation and Launch
                </h3>
                <ul className="leading-relaxed lg:text-2xl sm:text-xl tracking-wider text-primary-purple">
                  <li>1. Finalise smart contract deployment on Solana.</li>
                  <li>2. Add 85% of SCRAPS tokens to the liquidity pool.</li>
                  <li>3. Mint and list 69 NFTs on Magic Eden marketplace.</li>
                  <br />
                  <li>4. Launch social media channels: Twitter, Telegram</li>
                  <li>5. Host initial AMA sessions and giveaways.</li>
                </ul>
              </motion.div>

              {/* Phase 2 */}
              <motion.div
                className="flex-grow lg:w-1/3 flex flex-col items-center text-center bg-vivid-pink shadow-2xl p-12 rounded-lg font-bangers"
                variants={slideUp}
              >
                <h3
                  className="title-font sm:text-4xl text-3xl mb-6 font-medium text-primary-purple tracking-widest"
                  style={{
                    textShadow:
                      "2px 2px 0 rgb(230 237 122), -2px -2px 0 rgb(230 237 122), 2px -2px 0 rgb(230 237 122), -2px 2px 0 rgb(230 237 122)",
                  }}
                >
                  Phase 2: Expansion and Engagement
                </h3>
                <ul className="leading-relaxed lg:text-2xl sm:text-xl tracking-wider text-pale-yellow">
                  <li>
                    1. Utilize the 3% marketing allocation for influencer
                    partnerships, paid ads, and viral campaigns.
                  </li>
                  <li>
                    2. Create engaging content, memes, and promotional videos
                    with our in-house illustrator.
                  </li>
                  <br />
                  <li>3. Organize NFT holder-exclusive events and raffles.</li>
                  <li>
                    4. Distribute plush toys to NFT holders and share unboxing
                    moments across social platforms.
                  </li>
                  <br />
                  <li>
                    5. Monitor and rebalance liquidity to ensure token
                    stability.
                  </li>
                </ul>
              </motion.div>

              {/* Phase 3 */}
              <motion.div
                className="flex-grow lg:w-1/3 flex flex-col items-center text-center bg-pale-yellow shadow-2xl p-12 rounded-lg font-bangers"
                variants={slideUp}
              >
                <h3
                  className="title-font sm:text-4xl text-3xl mb-6 font-medium text-vivid-pink tracking-widest"
                  style={{
                    textShadow:
                      "2px 2px 0 rgb(153 70 255), -2px -2px 0 rgb(153 70 255), 2px -2px 0 rgb(153 70 255), -2px 2px 0 rgb(153 70 255)",
                  }}
                >
                  Phase 3: Ecosystem Development
                </h3>
                <ul className="leading-relaxed lg:text-2xl sm:text-xl tracking-wider text-primary-purple">
                  <li>
                    1. Explore utility features like staking SCRAPS tokens for
                    rewards.
                  </li>
                  <li>
                    2. Introduce token voting rights for future project
                    decisions.
                  </li>
                  <br />
                  <li>
                    3. Collaborate with meme projects, marketplaces, and
                    merchandise brands.
                  </li>
                  <br />
                  <li>
                    4. Plan additional NFT perks, such as exclusive merch drops
                    or token rewards.
                  </li>
                </ul>
              </motion.div>

              {/* Phase 4 */}
              <motion.div
                className="flex-grow lg:w-1/3 flex flex-col items-center text-center bg-vivid-pink shadow-2xl p-12 rounded-lg font-bangers"
                variants={slideUp}
              >
                <h3
                  className="title-font sm:text-4xl text-3xl mb-6 font-medium text-primary-purple tracking-widest"
                  style={{
                    textShadow:
                      "2px 2px 0 rgb(230 237 122), -2px -2px 0 rgb(230 237 122), 2px -2px 0 rgb(230 237 122), -2px 2px 0 rgb(230 237 122)",
                  }}
                >
                  Phase 4: Long-Term Growth
                </h3>
                <ul className="leading-relaxed lg:text-2xl sm:text-xl tracking-wider text-pale-yellow">
                  <li>
                    1. Establish The Den as an official online store for Scrappy
                    Raccoon merchandise.
                  </li>
                  <li>
                    2. Offer discounts or SCRAPS token payments for merchandise
                    purchases.
                  </li>
                  <br />
                  <li>
                    3. Launch a secondary NFT collection or collaborations based
                    on community feedback.
                  </li>
                  <br />
                  <li>
                    4. Publish a transparent report detailing token usage,
                    liquidity status, and future goals.
                  </li>
                  <li>
                    5. Host an annual NFT holder summit to celebrate milestones
                    and gather feedback.
                  </li>
                </ul>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </motion.section>

      <motion.section
        id="tokenomics"
        className="bg-primary-purple min-h-screen flex items-center justify-center body-font"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={fadeIn}
      >
        <div className="container w-full px-5 py-24 flex flex-col items-center justify-center">
          <motion.div
            className="bg-pale-yellow shadow-2xl m-12 p-12 rounded-lg flex-grow flex flex-col items-center text-center font-bangers w-full"
            variants={slideUp}
          >
            <motion.h2
              className="title-font sm:text-6xl text-5xl mb-6 font-medium text-vivid-pink tracking-wider"
              style={{
                textShadow:
                  "2px 2px 0 rgb(153 70 255), -2px -2px 0 rgb(153 70 255), 2px -2px 0 rgb(153 70 255), -2px 2px 0 rgb(153 70 255)",
              }}
              variants={scaleUp}
            >
              Tokenomics
            </motion.h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 w-full">
              <motion.div
                className="flex flex-col items-center text-center bg-primary-purple shadow-2xl p-8 rounded-lg font-bangers"
                variants={slideUp}
              >
                <h3
                  className="title-font text-3xl mb-4 font-medium text-pale-yellow tracking-widest"
                  style={{
                    textShadow:
                      "2px 2px 0 rgb(252 74 157), -2px -2px 0 rgb(252 74 157), 2px -2px 0 rgb(252 74 157), -2px 2px 0 rgb(252 74 157)",
                  }}
                >
                  Total Supply
                </h3>
                <p className="leading-relaxed text-3xl tracking-wider text-white">
                  1,000,000,000
                </p>
              </motion.div>

              <motion.div
                className="flex flex-col items-center text-center bg-primary-purple shadow-2xl p-8 rounded-lg font-bangers"
                variants={slideUp}
              >
                <h3
                  className="title-font text-3xl mb-4 font-medium text-pale-yellow tracking-widest"
                  style={{
                    textShadow:
                      "2px 2px 0 rgb(252 74 157), -2px -2px 0 rgb(252 74 157), 2px -2px 0 rgb(252 74 157), -2px 2px 0 rgb(252 74 157)",
                  }}
                >
                  Supply Allocation
                </h3>
                <p className="leading-relaxed text-3xl tracking-wider text-white">
                  85% LP, 12% Team, 3% Marketing
                </p>
              </motion.div>

              <motion.div
                className="flex flex-col items-center text-center bg-primary-purple shadow-2xl p-8 rounded-lg font-bangers"
                variants={slideUp}
              >
                <h3
                  className="title-font text-3xl mb-4 font-medium text-pale-yellow tracking-widest"
                  style={{
                    textShadow:
                      "2px 2px 0 rgb(252 74 157), -2px -2px 0 rgb(252 74 157), 2px -2px 0 rgb(252 74 157), -2px 2px 0 rgb(252 74 157)",
                  }}
                >
                  Ticker
                </h3>
                <p className="leading-relaxed text-3xl tracking-wider text-white">
                  $SCRAPS
                </p>
              </motion.div>
            </div>
          </motion.div>
          <motion.div
            className="bg-pale-yellow shadow-2xl m-12 p-12 rounded-lg flex-grow flex flex-col items-center text-center font-bangers w-full"
            variants={slideUp}
          >
            <motion.h2
              className="title-font sm:text-6xl text-5xl mb-6 font-medium text-vivid-pink tracking-wider"
              style={{
                textShadow:
                  "2px 2px 0 rgb(153 70 255), -2px -2px 0 rgb(153 70 255), 2px -2px 0 rgb(153 70 255), -2px 2px 0 rgb(153 70 255)",
              }}
              variants={scaleUp}
            >
              NFTs
            </motion.h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 w-full">
              <motion.div
                className="flex flex-col items-center text-center bg-primary-purple shadow-2xl p-8 rounded-lg font-bangers"
                variants={slideUp}
              >
                <h3
                  className="title-font text-3xl mb-4 font-medium text-pale-yellow tracking-widest"
                  style={{
                    textShadow:
                      "2px 2px 0 rgb(252 74 157), -2px -2px 0 rgb(252 74 157), 2px -2px 0 rgb(252 74 157), -2px 2px 0 rgb(252 74 157)",
                  }}
                >
                  Collection Name
                </h3>
                <p className="leading-relaxed text-3xl tracking-wider text-white">
                  The Den
                </p>
              </motion.div>

              <motion.div
                className="flex flex-col items-center text-center bg-primary-purple shadow-2xl p-8 rounded-lg font-bangers"
                variants={slideUp}
              >
                <h3
                  className="title-font text-3xl mb-4 font-medium text-pale-yellow tracking-widest"
                  style={{
                    textShadow:
                      "2px 2px 0 rgb(252 74 157), -2px -2px 0 rgb(252 74 157), 2px -2px 0 rgb(252 74 157), -2px 2px 0 rgb(252 74 157)",
                  }}
                >
                  Total NFTs
                </h3>
                <p className="leading-relaxed text-3xl tracking-wider text-white">
                  69
                </p>
              </motion.div>

              <motion.div
                className="flex flex-col items-center text-center bg-primary-purple shadow-2xl p-8 rounded-lg font-bangers"
                variants={slideUp}
              >
                <h3
                  className="title-font text-3xl mb-4 font-medium text-pale-yellow tracking-widest"
                  style={{
                    textShadow:
                      "2px 2px 0 rgb(252 74 157), -2px -2px 0 rgb(252 74 157), 2px -2px 0 rgb(252 74 157), -2px 2px 0 rgb(252 74 157)",
                  }}
                >
                  Price at Mint
                </h3>
                <p className="leading-relaxed text-3xl tracking-wider text-white">
                  0.3 SOL
                </p>
              </motion.div>
            </div>

            <motion.div
              className="flex flex-col items-center text-center bg-primary-purple shadow-2xl mt-8 p-8 rounded-lg font-bangers"
              variants={slideUp}
            >
              <h3
                className="title-font text-3xl mb-4 font-medium text-pale-yellow tracking-widest"
                style={{
                  textShadow:
                    "2px 2px 0 rgb(252 74 157), -2px -2px 0 rgb(252 74 157), 2px -2px 0 rgb(252 74 157), -2px 2px 0 rgb(252 74 157)",
                }}
              >
                Perks:
              </h3>
              <p className="leading-relaxed text-3xl tracking-wider text-white">
                Each NFT purchase includes a Scrappy Raccoons plush toy
                delivered from The Den all royalties from sales also used in buy
                backs for $Scraps
              </p>
            </motion.div>
          </motion.div>
        </div>
      </motion.section>

      {/* <section id="chart" className="bg-primary-purple text-primary-purple min-h-screen flex items-center justify-center body-font">
        <div className="container w-full px-5 py-24 flex flex-col md:flex-row items-center justify-center flex-grow">
          <div className="bg-white text-vivid-pink shadow-2xl m-12 p-12 rounded-lg flex-grow flex flex-col items-center text-center font-bangers w-full">
            <h2 className="title-font sm:text-6xl text-5xl mb-6 font-mediu tracking-wider"
              style={{
                textShadow: '2px 2px 0 rgb(153 70 255), -2px -2px 0 rgb(153 70 255), 2px -2px 0 rgb(153 70 255), -2px 2px 0 rgb(153 70 255)'
              }}>
              Check our very fiesty chart!
            </h2>


            <div id="dexscreener-embed">
              <iframe src="https://dexscreener.com/solana/9DJrGmVqHxejzErYsc4LwCSaeAePXH1pCxUzLJVNfV77?embed=1&theme=dark&trades=0"></iframe>
            </div>
          
          </div>
        </div>
      </section> */}

      <motion.section
        id="about"
        className="text-white min-h-screen flex flex-col justify-center body-font"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={fadeIn}
      >
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-center flex-grow py-24 px-5">
          <div className="flex flex-col md:flex-row w-full">
            <motion.div
              className="lg:flex-grow md:w-1/2 flex items-center justify-center mx-4 mt-8 md:mt-0"
              variants={scaleUp}
            >
              <img
                className="w-full h-auto max-w-full object-cover object-center rounded-lg"
                src={purpleyellowbody}
                alt="SCRAPS Meme Coin"
              />
            </motion.div>
            <motion.div
              className="lg:flex-grow md:w-1/2 flex flex-col justify-between md:items-start md:text-left items-center text-center bg-primary-purple shadow-2xl p-12 rounded-lg font-bangers mx-4"
              variants={slideUp}
            >
              <div>
                <motion.h1
                  className="title-font mb-6 text-4xl sm:text-5xl md:text-6xl lg:text-6xl font-medium text-pale-yellow tracking-wider"
                  style={{
                    textShadow:
                      "2px 2px 0 rgb(252 74 157), -2px -2px 0 rgb(252 74 157), 2px -2px 0 rgb(252 74 157), -2px 2px 0 rgb(252 74 157)",
                  }}
                  variants={scaleUp}
                >
                  Get your $SCRAPS now!
                </motion.h1>
                <motion.p
                  className="mb-8 text-sm sm:text-base md:text-lg lg:text-xl leading-relaxed tracking-wider text-white"
                  variants={slideUp}
                >
                  $SCRAPS is available on Jupiter and Raydium. Click the links
                  below to buy now!
                </motion.p>
                <motion.p
                  className="mb-8 text-sm sm:text-base md:text-lg lg:text-xl leading-relaxed tracking-wider text-white flex items-center space-x-2"
                  variants={slideUp}
                >
                  <strong>Contract Address:</strong>
                  <span className="bg-white text-secondary-purple px-2 py-1 rounded">
                    {contractAddress}
                  </span>
                  <motion.button
                    onClick={copyToClipboard}
                    className="bg-secondary-purple text-white px-2 py-1 rounded ml-2"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    Copy
                  </motion.button>
                </motion.p>
              </div>
              <div className="mt-auto w-full">
                <div className="flex justify-center items-center w-full">
                  <motion.a
                    href={dexscreenerLink}
                    target="_blank"
                    className="hover:text-white transition-transform duration-300 transform hover:scale-110"
                    whileHover={{ scale: 1.1 }}
                  >
                    <img
                      src={Dexscreener}
                      className="w-12 h-12 md:w-22 md:h-22 lg:w-32 lg:h-32 rounded-full mx-4"
                      alt="DexScreener"
                    />
                  </motion.a>
                  <motion.a
                    href={dextoolsLink}
                    target="_blank"
                    className="hover:text-white transition-transform duration-300 transform hover:scale-110"
                    whileHover={{ scale: 1.1 }}
                  >
                    <img
                      src={Dextools}
                      className="w-12 h-12 md:w-22 md:h-22 lg:w-32 lg:h-32 mx-4"
                      alt="DexTools"
                    />
                  </motion.a>
                  <motion.a
                    href={jupiterLink}
                    target="_blank"
                    className="hover:text-white transition-transform duration-300 transform hover:scale-110"
                    whileHover={{ scale: 1.1 }}
                  >
                    <img
                      src={Jupiter}
                      className="w-12 h-12 md:w-22 md:h-22 lg:w-32 lg:h-32 mx-4"
                      alt="Jupiter"
                    />
                  </motion.a>
                  <motion.a
                    href={raydiumLink}
                    target="_blank"
                    className="hover:text-white transition-transform duration-300 transform hover:scale-110"
                    whileHover={{ scale: 1.1 }}
                  >
                    <img
                      src={Raydium}
                      className="w-12 h-12 md:w-24 md:h-22 lg:w-32 lg:h-32 mx-4"
                      alt="Raydium"
                    />
                  </motion.a>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.section>

      <section
        id="footer"
        className="text-white bg-secondary-purple flex flex-col items-center justify-center body-font p-8 font-bangers"
      >
        <p className="text-center text-xl tracking-wider mb-4">
          $SCRAPS is a meme coin with no intrinsic value or expectation of
          financial return. The coin is for entertainment purposes only.
        </p>
        <p className="text-center text-xl tracking-wider mb-2">
          © 2024 by $SCRAPS. All rights reserved!
        </p>
        <p className="text-center text-xl tracking-wider">Developed by Ruroc</p>
      </section>
    </div>
  );
}

export default App;
